@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    html {
      @apply text-gray-900;
    } 
  }
body {
    overflow-x: hidden;
  }
  
  
/* width */
.PlayerStatBox::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.PlayerStatBox::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 2px;
}

/* Handle */
.PlayerStatBox::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2px;
  transition: all;
}

/* Handle on hover */
.PlayerStatBox::-webkit-scrollbar-thumb:hover {
  background: #555;
}
